import React from 'react'
import styled from "styled-components";
import { FaRegCopyright } from "react-icons/fa";

const Styles = styled.div`
.footer {
    margin-top: 5rem;
    padding-top: 10px;
    text-align: center;
    width: 100%;
    height: 3rem;
    background-color: #252727;
    // border-top: 2px solid black;
    position: fixed;
    bottom: 0;
    z-index: 5;
}

.txt {
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  // font-weight: bold;
  color: #fff;
  
}

@media only screen and (max-width: 1200px){
  .txt {
    font-size: 15px;       
  }
@media (max-width: 476px) {
  .txt {
    font-size: 12px;       
  }
}

}


`;

function Footer() {
  return (
    <>
    <Styles>
    <div className='footer'>
      <div className='txt'>Copyright <span>
      <FaRegCopyright />
        </span> 2024 Zosh Aerospace - All Rights Reserved</div>
    </div>
    </Styles>
    </>
  );
}

export default Footer
