import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import About from "./About"
import zoshLogo from "../images/zosh_logo.png";
import makeInIndia from "../images/make-in-India.jpg";

const Styles = styled.div`
  .ms-auto {
    font-size: 20px;
  }
  .nav1 {
    //background-color: none;
    width: 100%;
    height: 5rem;
    position: fixed;
    z-index: 5;
    font-family: 'Roboto', sans-serif;
  }

  .Lfont1 {
    margin-left: -5rem;
    font-size: 48px; 
    text-decoration: none;
    // border: 1px solid black;
    padding: 0px;
    font-family: Stencil;
  }

  .Lfont1:hover {
    text-decoration: none;
  }

  .Lfont2:hover {
    text-decoration: none;
    color: #5ce84f;
  }

  .Lfont2 {
    margin-left: -6rem;
    font-size: 24px;
    font-family: Times New Roman;
    color: #5ce84f;
    text-decoration: none;
    // border: 1px solid black;
    padding: 10px;
    position: relative;
    top: -20px;
    left: -6px;
  }

  span {
    padding: 10px;
    font-weight: normal;
    color: #fff;
    font-size: 20px;
    position: relative; /* Add this line */
  }
  
  span:before {
    content: '';
    position: absolute;
    bottom: -1px; /* Adjust as needed to position the underline */
    left: 0;
    width: 0;
    height: 1px;
    background-color: #fff; /* Change this to your desired underline color */
    transition: width 0.2s ease-in-out; /* Adjust the duration and easing as needed */
  }
  
  span:hover:before {
    width: 100%;
  }

  #basic-nav-dropdown {
    color: #fff;
    font-size: 20px;
  }
  .brand-image {
    width: 160px;
    height: 50px;
  }
  .brand-image1 {
    width: 130px;
    height: 50px;
  }
  .dropdown-menu {
    margin: 0;
    padding: 0;
  } 
  
  #navitemz{
    color:white;
    background-color:#4a4f4f;
    padding:10px;
    border-bottom: 0.5px solid grey;
  }
  #navitemz:hover{
    background-color:#252727;
  }

  // ---------------- Mobile view -----------------

  @media only screen and (max-width: 1200px){

    .Lfont1 {
      margin-left: 1rem;
      font-size: 48px; 
      text-decoration: none;
      // border: 1px solid black;
      padding: 0px;
      font-family: Stencil;
      position: relative;
      top: -10px;
    }
  
    .Lfont1:hover {
      text-decoration: none;
    }
  
    .Lfont2:hover {
      text-decoration: none;
      color: #5ce84f;
    }
  
    .Lfont2 {
      margin-left: 0rem;
      font-size: 24px;
      font-family: Times New Roman;
      color: #5ce84f;
      text-decoration: none;
      // border: 1px solid black;
      padding: 10px;
      position: relative;
      top: -30px;
      left: -6px;
    } 

    .nav1 {
      background-color: #989a9a;
      width: 100%;
      height: 5rem;
      position: fixed;
      z-index: 5;
      // border: 1px solid white;
    }

    .ms-auto {
      font-size: 20px;
      background-color: #4a4f4f;
      width: 250px;
      text-align: center;
      position: absolute;
      right: 0;
      top: 5rem;
    }

    .menu-btn {
      color: #fff;
      //background-color: #fff;
      margin-bottom: 0rem;
      margin-right: 0.5rem;
    }

    .brand-image {
      width: 140px;
      height: 45px;
    }

    .brand-image1 {
      width: 130px;
      height: 50px;
      margin: 10px 10px 10px 50px;
    }
    
    .navbar .ms-auto .nav-link {
      margin-bottom: 10px;      
    }

    .navbar .ms-auto .nav-link:not(#about){
      border-bottom: 0.5px solid grey;
    }

    #navitemz{
      color:white;
      background-color:#252727;
      padding:10px;
      text-align:center;
      //border-bottom: 0.5px solid grey;
    }
    #navitemz:hover{
      background-color:#4a4f4f;
    }

  }
`;

function Header() {
  return (
    <>
      <Styles>
        <Navbar className="nav1" expand="xl" variant="dark" style={{ backgroundColor: "rgba(37, 39, 39, 1)" }}>
          <div className="container-fluid">
            <Navbar.Brand href="home">
              {/* <span className="Lfont1">
                ZOSH
              </span>
              <br />
              <span
              className="Lfont2"
              >
                AEROSPACE
              </span> */}
              <img className="brand-image d-inline-block align-top" src={zoshLogo} alt="ZOSH AEROSPACE" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="menu-btn"/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {/* <Nav.Link href="home">
                  <span>Home</span>
                </Nav.Link> */}
                {/* <Nav.Link href="home">
                  <span>About Us</span>
                </Nav.Link> */}
                {/* <Nav.Link href="About"><span>What we do</span></Nav.Link> */}
                <NavDropdown title="What we do" id="basic-nav-dropdown">
                  <NavDropdown.Item href="rugged" id="navitemz">Rugged Products</NavDropdown.Item>
                  {/* <NavDropdown.Divider /> */}
                  <NavDropdown.Item href="simulator" id="navitemz">Simulator</NavDropdown.Item>
                  
                  <NavDropdown.Item href="testjig" id="navitemz">Test Jig</NavDropdown.Item>
                  
                  <NavDropdown.Item href="ate" id="navitemz">ATE</NavDropdown.Item>
                  
                  <NavDropdown.Item href="ets" id="navitemz">ETS</NavDropdown.Item>
                
                  <NavDropdown.Item href="antenna" id="navitemz">Antenna & RF</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="solution">
                  <span>Our Solutions</span>
                </Nav.Link>
                {/* <Nav.Link href="About">
                  <span>Zosh Careers</span>
                </Nav.Link> */}
                <Nav.Link href="suppliers">
                  <span>Suppliers</span>
                </Nav.Link>
                <Nav.Link href="news">
                  <span>News & Updates</span>
                </Nav.Link>
                <Nav.Link href="contact">
                  <span>Contact Us</span>
                </Nav.Link>
                <Nav.Link href="" id="about">
                  <About />
                </Nav.Link>                
                {/* <img className="brand-image1 d-inline-block align-top" src={makeInIndia} alt="Make in India Logo" />                   */}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </Styles>
    </>
  );
}
export default Header;
